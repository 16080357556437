@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
@import './quasar.variables.sass'
@import './quasar.overrides.sass'
@import '../../../../lib/css/hc.sass'
@import '../../../../lib/css/loops.sass'
@import '../../../../lib/css/unicons.sass'
@import '../../../../lib/css/poppins.sass'

// Defaults

body
  font-size: 15px
  font-family: 'Poppins', sans-serif
  color: $dark
  font-display: swap
  background: $grey-1

// Dark mode declaration
body.body--light
  --q-primary: $primary
  --q-secondary: $secondary
  --q-accent: $accent
  --q-dark: $dark
  --q-positive: $positive
  --q-negative: $negative
  --q-info: $info
  --q-warning: $warning

  .hc-link
    text-decoration: none
    color: $grey-7
    &:hover
      color: $primary

  .bg-gradient
    background: rgb(238,229,254)
    background: linear-gradient(180deg, rgba(238,229,254,1) 0%, rgba(211,243,255,1) 100%)

body.body--dark
  --q-primary: $primary-dark
  --q-secondary: $secondary-dark
  --q-accent: $accent-dark
  --q-dark: $dark-dark
  --q-positive: $positive-dark
  --q-negative: $negative-dark
  --q-info: $info-dark
  --q-warning: $warning-dark

  .hc-link
    text-decoration: none
    color: $grey-1
    &:hover
      color: $primary-dark

  .bg-gradient
    background: rgb(238,229,254)
    background: linear-gradient(180deg, rgba(238,229,254,0.3) 0%, rgba(211,243,255,0.3) 100%)

// Custom classes
// The ones containing color values should be double declared
// in the body--light and body--dark selectors above
